import { createRouter, createWebHistory } from 'vue-router'
import PrivatePromptsMain from '@/components/PrivatePromptsMain.vue'
import ImpressumPage from '@/components/ImpressumPage.vue'
import PrivacyStatementPage from '@/components/PrivacyStatementPage.vue'

const routes = [
    { path: '/', name: 'PrivatePromptsMain', component: PrivatePromptsMain },
    { path: '/impressum', name: 'Impressum', component: ImpressumPage },
    { path: '/datenschutz', name: 'PrivacyStatement', component: PrivacyStatementPage }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
