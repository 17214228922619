<!-- PrivatePromptsMain.vue -->
<template>

  <!-- Banner mit Hintergrundfarbe -->
  <v-container fluid class="banner">
    <v-row class="d-flex align-center justify-space-between">
      <!-- Text zentriert im Banner -->
      <v-col cols="12" md="11" class="d-flex justify-center text-center">
        <v-img src="./logo.png" alt="Private Prompts Logo" contain max-width="150"></v-img>
        <div>
          <h3>
            Deine Daten gehören dir!
          </h3>
          <h1 class="font-weight-bold">
            Private Prompts
          </h1>
          <h4>
            KI und Webdienste sicher nutzen
          </h4>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <v-container fluid class="status-message" style="padding-bottom: 8px;">
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6" class="text-center">
        <p>
          Wer KI-Dienste nutzt, schickt oft unbewusst persönliche Daten ins Netz.
          Mit der kostenlosen Anwendung <span class="brand-name">Private Prompts</span> bleiben deine Daten dort, wo sie
          hingehören - bei dir auf
          deinem Rechner.
        </p>
      </v-col>
    </v-row>
  </v-container>

  <!-- Demo Image/Video-Bereich -->
  <v-container class="teaser-image-section" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card outlined hover class="video-card">
          <v-img src="./private-prompts-data-flow.png" aspect-ratio="16/9" alt="Der Datenfluss von Private Prompts">
          </v-img>
          <v-card-text class="text-center">
            Die Anwendung <span class="brand-name">Private
              Prompts</span> erkennt und schützt sensible Informationen in deinen Prompts. Nach der
            Verarbeitung durch eine KI oder einen anderen Webservice stellt <span class="brand-name">Private
              Prompts</span> die ursprünglichen Daten wieder her. So wird deine Privatsphäre besser geschützt.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card outlined hover class="video-card">
          <v-card-text class=" text-center">
            <video width="100%" controls>
              <source src="https://privateprompts.org/videos/private-prompts-demo-translate.mp4" type="video/mp4" />
            </video>
            <br />
            Dieses Beispiel demonstriert, wie du mit <span class=" brand-name">Private
              Prompts</span> Webanwendungen wie zum Beispiel Google Translate nutzen kannst, ohne dabei Daten
            preiszugeben, die dir wichtig sind.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>


  <!-- Schnellstart Download -->
  <v-container fluid class="quickstart">

    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <h2>Download & Installation</h2>
      </v-col>
      <v-col cols="12" md="10" class="text-center">
        <p>
          Du weißt, was du möchtest und magst nicht viel lesen? Dann kannst du die neueste Version von <span
            class="brand-name">Private
            Prompts</span> direkt hier herunterladen. <span class="brand-name">Private
            Prompts</span> läuft stabil und
          ist <strong>kostenlos</strong> für die Plattformen Windows, Linux und macOS verfügbar.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="8" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <br />
            <strong>Windows</strong>
            <br />
            <br />
            <v-btn
              href="https://github.com/fboerncke/private-prompts-prototype/releases/download/PrivatePrompts-0.0.5-beta/PrivatePrompts-0.0.5-beta.exe"
              target="_blank">
              <v-icon size="32">mdi-microsoft-windows</v-icon>&nbsp;PrivatePrompts-0.0.5-beta.exe</v-btn>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            <v-list density="compact">
              <v-list-item>
                1. Lade die Installationsdatei herunter und starte sie mit einem Doppelklick.
              </v-list-item>
              <v-list-item>
                2. Folge gegebenenfalls den Anweisungen der Windows-Sicherheitsabfragen und bestätige den
                Hinweis, dass
                die Datei aus dem Internet geladen wurde.
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="8" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <br />
            <strong>Linux</strong>
            <br />
            <br />
            <v-btn
              href="https://github.com/fboerncke/private-prompts-prototype/releases/download/PrivatePrompts-0.0.5-beta/PrivatePrompts-0.0.5-beta.Appimage"
              target="_blank">
              <v-icon size="32">mdi-penguin</v-icon>&nbsp;PrivatePrompts-0.0.5-beta.Appimage</v-btn>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            <v-list density="compact">
              <v-list-item>
                1. Lade die Installationsdatei herunter.
              </v-list-item>
              <v-list-item>
                2. Mache die Datei ausführbar, indem du folgenden Befehl im Terminal
                eingibst:
                <pre style="display: inline;"><code>chmod u+x PrivatePrompts-0.0.5-beta.AppImage</code></pre>
                <v-btn icon @click="copyToClipboard('chmod u+x PrivatePrompts-0.0.5-beta.AppImage')" class="no-hover"
                  style="background: none; box-shadow: none; padding: 0; height:10px; display: inline;">
                  <v-icon size="16" style="width:10px;height:10px;" color="black">mdi-content-copy</v-icon>
                </v-btn>
              </v-list-item>
              <v-list-item>
                3. Doppelklicke anschließend auf die Datei oder starte sie aus dem Terminal heraus:
                <pre style="display: inline;"><code>./PrivatePrompts-0.0.5-beta.AppImage</code></pre>
                <v-btn icon @click="copyToClipboard('./PrivatePrompts-0.0.5-beta.AppImage')" class="no-hover"
                  style="background: none; box-shadow: none; padding: 0; height:10px; display: inline;">
                  <v-icon size="16" style="width:10px;height:10px;" color="black">mdi-content-copy</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="8" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <br />
            <strong>macOS (Intel und Silicon)</strong>
            <br />
            <br />
            <v-btn
              href="https://github.com/fboerncke/private-prompts-prototype/releases/download/PrivatePrompts-0.0.5-beta/private-prompts-0.0.5-beta-universal.dmg"
              target="_blank">
              <v-icon size="32">mdi-apple</v-icon>&nbsp;private-prompts-0.0.5-beta-universal.dmg
            </v-btn>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            <v-list density="compact">
              <v-list-item>
                1. Lade die dmg-Datei herunter und öffne sie mit Doppelklick.
              </v-list-item>
              <v-list-item>
                2. Schiebe die Datei "Private Prompts.app" an einen Ort deiner Wahl.
              </v-list-item>
              <v-list-item>
                3. Starte die Datei mit Doppelklick und bestätige den Hinweis, dass die Datei aus dem Internet geladen
                wurde.
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <h3>Ältere Versionen</h3>
      </v-col>
      <v-col cols="12" md="10" class="text-center">
        <p>Ältere Versionen der Anwendung mit Changelogs und Release Notes findest du hier:</p>
        <p><v-btn href="https://github.com/fboerncke/private-prompts-prototype/releases" target="_blank">
            <v-icon size="32">mdi-cloud-download-outline</v-icon>&nbsp;Downloadseite mit Release Notes
            (Englisch)</v-btn>
        </p>
        <p><span class="brand-name">Private Prompts</span> entwickelt sich stetig weiter – mit deinem Feedback. Teste
          neue Versionen und gestalte die Zukunft sicherer KI-Nutzung mit.</p>
      </v-col>
    </v-row>


  </v-container>

  <!-- Schritt-für-Schritt-Anleitung -->
  <v-container class="how-it-works" fluid>
    <v-row>
      <v-col cols="12" class="text-center">
        <h2>So funktioniert es</h2>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-cloud-download</v-icon>
        <h3>1. Download</h3>
        <p>
          Lade <span class="brand-name">Private Prompts</span> kostenlos herunter. Für Windows, Linux und macOS gibt es
          ausführbare Dateien, die einfach gestartet werden können. Eine
          Konfigurationsdatei wird auf deinem Rechner gespeichert, sodass du Eingaben nicht erneut vornehmen musst.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-fingerprint</v-icon>
        <h3>2. Mehr Sicherheit</h3>
        <p>
          Nutze KI mit mehr Sicherheit. Du definierst nach deinen Vorstellungen, was für dich privat ist
          und welche Informationen
          besser
          auf deinem Rechner bleiben sollen, wenn du Dienste aus dem Internet verwendest.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-chat-processing</v-icon>
        <h3>3. Loslegen</h3>
        <p>
          Das folgende Beispiel zeigt, wie <span class="brand-name">Private Prompts</span> funktioniert:
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-carousel height="auto" hide-delimiter-background>

          <!-- Slide 1 -->
          <v-carousel-item>
            <div class="caption">
              Nalea Thornfeld möchte sich mit Hilfe einer KI ein Bewerbungsschreiben generieren lassen. Der Prompt dafür
              enthält viele persönliche Daten – Name, Adresse, bisherige Arbeitgeber. Nalea fühlt sich unwohl, diese
              Informationen an einen KI-Dienst zu schicken. Doch was kann sie tun, um ihre Privatsphäre zu schützen?
            </div>
            <v-img src="./slideshow/01.png" alt="Prompt für Erstellung eines Bewerbungsschreibens"
              class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 2 -->
          <v-carousel-item>
            <div class="caption">
              Mit dem Knopf “MARK” lässt Nalea sich die sensiblen Stellen im Prompt markieren. Auf einen Blick erkennt
              sie, welche Informationen geschützt werden sollten. Sie könnte diese Daten manuell entfernen, entscheidet
              sich jedoch für die komfortablere Lösung: die automatische Maskierung durch Private Prompts.
            </div>
            <v-img src="./slideshow/02.png" alt="Sensitive Inhalte werden maskiert" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 3 -->
          <v-carousel-item>
            <div class="caption">
              Ein Klick auf “MASK” ersetzt die sensiblen Inhalte durch Platzhalter. Aus “Nalea Thornfeld” wird
              beispielsweise “Jane Doe”. Private Prompts merkt sich alle Ersetzungen, um sie später wieder rückgängig
              machen zu können. So bleibt der Prompt anonym, ohne an Klarheit zu verlieren.
            </div>
            <v-img src="./slideshow/03.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 4 -->
          <v-carousel-item>
            <div class="caption">
              Nalea kopiert den pseudonymisierten Prompt mit "Copy & Paste" und führt ihn in einem KI-Dienst ihrer Wahl
              aus. Das generierte Bewerbungsschreiben enthält ebenfalls nur Platzhalter. Ihre sensiblen Daten bleiben
              verborgen, während die KI trotzdem präzise Ergebnisse liefert.
            </div>
            <v-img src="./slideshow/04.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 5 -->
          <v-carousel-item>
            <div class="caption">
              Das von der KI generierte Bewerbungsschreiben mit allen pseudonymisierten Platzhaltern kopiert Nalea nun
              zurück nach Private Prompts. Dort kann sie das Ergebnis manuell bearbeiten und den nächsten Schritt
              vorbereiten.
            </div>
            <v-img src="./slideshow/05.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 6 -->
          <v-carousel-item>
            <div class="caption">
              Ein Klick auf “UNMASK” ersetzt die Platzhalter wieder durch die ursprünglichen Originaldaten. Private
              Prompts hat Naleas Privatsphäre geschützt. Nalea hat nun als Ergebnis den gewünschten Entwurf für ein
              Bewerbungsschreiben und die KI hat ihre persönlichen Daten nie gesehen.
            </div>
            <v-img src="./slideshow/06.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 7 -->
          <v-carousel-item>
            <div class="caption">
              Um Zeit zu sparen, hinterlegt Nalea in den Einstellungen von Private Prompts einen privaten
              OpenAI-API-Schlüssel. Dadurch kann sie den “SEND TO AI”-Button nutzen, der alle Schritte automatisch
              ausführt. Diese Funktion ist praktisch, aber optional – Private Prompts funktioniert auch ohne
              API-Schlüssel.
            </div>
            <v-img src="./slideshow/07.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 8 -->
          <v-carousel-item>
            <div class="caption">
              Im Regeleditor legt Nalea fest, welche Informationen sie schützen möchte und welche Platzhalter dafür
              verwendet werden. Dieses persönliche Wörterbuch gibt ihr volle Kontrolle über ihre Daten. Private Prompts
              sorgt dafür, dass sensible Informationen sicher auf ihrem eigenen Rechner bleiben – und nicht bei einem
              KI-Dienst landen.
            </div>
            <v-img src="./slideshow/08.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>

          <!-- Slide 9 -->
          <v-carousel-item>
            <div class="caption">
              Im Prompt Manager organisiert Nalea ihre häufig genutzten Prompts. Mit Hilfe von Tags kann sie ihre
              Sammlung strukturieren und gezielt nach Ergebnissen filtern. Wichtige Prompts lassen sich anpinnen, um sie
              schnell wiederzufinden. So bleibt alles übersichtlich und jederzeit griffbereit.
            </div>
            <v-img src="./slideshow/09.png" alt="Sicherer Umgang mit KI" class="carousel-image"></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

  </v-container>

  <!-- Vorteile auf einen Blick -->
  <v-container class="benefits  pl-9 pr-9" fluid>
    <v-row>
      <v-col cols="12" class="text-center">
        <h2>Deine Vorteile auf einen Blick</h2>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-check-bold</v-icon>
        <h3>Keine Cloud</h3>
        <p>
          Behalte die Kontrolle über deine sensiblen Daten: Mit <span class="brand-name">Private Prompts</span> bleiben
          alle Informationen
          sicher auf deinem eigenen Rechner. Keine unerwünschte Weitergabe, kein Risiko.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-check-bold</v-icon>
        <h3>Intuitive Bedienung</h3>
        <p>
          Starte sofort auch ohne Vorkenntnisse. <span class="brand-name">Private Prompts</span> ist
          so gestaltet, dass du es ohne Vorkenntnisse nutzen kannst. Einfach herunterladen, starten und loslegen.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-check-bold</v-icon>

        <h3>Du entscheidest</h3>
        <p>
          Bestimme selbst, welche Informationen privat bleiben sollen. Mit <span class="brand-name">Private
            Prompts</span> kannst du festlegen, welche Daten auf deinem Rechner bleiben und welche verarbeitet werden
          dürfen.
        </p>
      </v-col>
    </v-row>
  </v-container>

  <!-- Anwendungsbeispiele -->
  <v-container class="use-cases pl-9 pr-9" fluid>
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-icon size="64" color="secondary">mdi-sitemap</v-icon>

        <h2>Anwendungsbeispiele</h2>
      </v-col>
      <!-- Beispiel 1 -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <strong>Schreibe sichere Briefe</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Verfasse Dokumente oder vertrauliche E-Mails, ohne dir Sorgen um Datenschutz machen zu müssen. <span
              class="brand-name">Private Prompts</span> hilft dir dabei, vertrauliche
            E-Mails, Angebote oder Berichte
            zu verfassen, während sensible Informationen geschützt bleiben.
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Beispiel 2 -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <strong>Schutz für Kundendaten</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Verbessere deinen Kundenservice, ohne Kompromisse beim Datenschutz einzugehen. <span
              class="brand-name">Private
              Prompts</span> hilft dir, Kundenanfragen effizient zu bearbeiten und dabei trotzdem die persönlichen
            Daten deiner
            Kunden zu
            schützen.
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Beispiel 3 -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <strong>Texte sicher übersetzen</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Möchtest du wichtige Dokumente oder persönliche Nachrichten übersetzen, ohne sensible Inhalte offenzulegen?
            Mit <span class="brand-name">Private Prompts</span> kannst du Übersetzungsdienste wie <i>Google
              Translate</i> oder <i>deepl.com</i> benutzen, ohne persönliche Daten zu übertragen.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- Feedback und Mitwirkung -->
  <v-container class="feedback" fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <v-icon size="64" color="secondary">mdi-account-group</v-icon>
        <h2>Mach mit bei Private Prompts</h2>
        <p>
          Deine Meinung zu <span class="brand-name">Private Prompts</span> interessiert mich:
          Teile deine Ideen und Wünsche mit mir, um <span class="brand-name">Private Prompts</span> zu einer Lösung zu
          machen, die viele Menschen nützlich finden. Gemeinsam machen wir die Anwendung besser.
        </p>
        <v-btn href="mailto:frank.boerncke@gmail.com" target="_blank">
          <v-icon left>mdi-message-text-outline</v-icon>&nbsp;Feedback - frank.boerncke@gmail.com</v-btn>
      </v-col>
    </v-row>
    <!-- Video-Bereich -->
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card outlined hover class="video-card">
          <!-- Schräger Banner oben rechts -->
          <div class="video-banner">Dieses Video<br />wurde mit Hilfe<br />von KI erstellt</div>
          <v-img src="./mvp-teaser-image.png" aspect-ratio="16/9"
            alt="Vorschaubild des Einführungsvideos zu Private Prompts">
            <!-- Play-Button in der Mitte -->
            <v-container fluid justify="center">
              <v-btn @click="playVideo">Video abspielen</v-btn>
            </v-container>
          </v-img>
          <v-card-text class="text-center">
            In diesem kurzen Video zum Projektstart im Herbst 2024 erfährst du, wie <span class="brand-name">Private
              Prompts</span> deine persönlichen
            Daten schützt,
            dir mehr Kontrolle über deine Privatsphäre gibt und wie du das Projekt aktiv unterstützen kannst.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>


  <!-- FAQ -->
  <v-container class="frequently-asked-questions pl-9 pr-9" fluid>
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-icon size="64" color="secondary">mdi-tooltip-question</v-icon>

        <h2>F.A.Q. - Häufige Fragen und Antworten</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Was ist Private Prompts?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            <span class="brand-name">Private Prompts</span> ist eine Desktop-Anwendung, die KI-Nutzung mit erhöhter
            Sicherheit ermöglicht. Sensible Daten bleiben auf dem eigenen Gerät, sodass Texte,
            Dokumente oder E-Mails mit privaten Daten sicher erstellt werden können, ohne das
            vertrauliche Elemente an externe Server weitergegeben werden müssen. Wie das funktioniert, erfährst du in
            der Schritt-für-Schritt-Anleitung oder den folgenden Antworten.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Ist Private Prompts kostenlos?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Ja, die Desktopanwendung <span class="brand-name">Private Prompts</span> ist freie Software und kann
            kostenlos benutzt werden. Das wird auch in Zukunft so bleiben. Die Anwendung ist Open Source und kann von
            jedem Entwickler überprüft, verbessert und weiterentwickelt werden. Der Quellcode der Software
            ist auf GitHub verfügbar. Wenn du die Entwicklung unterstützen möchtest, freue ich mich über deine
            Mitarbeit.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Warum gibt es Private Prompts nicht als Webanwendung?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            <span class="brand-name">Private
              Prompts</span> wurde für Menschen entwickelt, die ihre Daten schützen und nicht an Webdienste weitergeben
            möchten. Eine Webanwendung würde diesen Datenschutzansatz untergraben, da die Verarbeitung dann auf fremden
            Servern stattfindet. <span class="brand-name">Private
              Prompts</span> läuft ausschließlich lokal auf dem eigenen Computer.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Ist Private Prompts für Laien nutzbar oder braucht man technisches Wissen?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Jeder, der mit ChatGPT oder ähnlichen KI-Tools umgehen kann, wird auch mit <span class="brand-name">Private
              Prompts</span> zurechtkommen.
            Die Benutzeroberfläche ist intuitiv gestaltet, sodass grundlegende Funktionen ohne technisches Vorwissen
            genutzt werden können. Für fortgeschrittene Anwender gibt es zusätzliche Anpassungsoptionen.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Warum sind meine Daten gefährdet?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Beim Absenden von Daten an eine KI besteht ein Kontrollverlust. Es ist unklar, was mit den Informationen
            passiert – sie könnten gespeichert, analysiert oder für das Training zukünftiger Modelle genutzt werden.
            Werden Daten auf externen Servern verarbeitet, besteht zudem das Risiko von Datenlecks, unbefugtem Zugriff
            oder Missbrauch.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Wie wird gewährleistet, dass meine Daten privat bleiben?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Du entscheidest selber, welche Daten als sensibel behandelt werden, etwa Namen, Kreditkartennummern oder
            Adressen. Diese Informationen verbleiben ausschließlich auf dem eigenen Gerät. Es gibt weder
            Cloud-Speicherung noch versteckte Datenübertragungen – die Sicherheit hängt allein von der eigenen
            Systemumgebung ab.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong> Brauche ich Private Prompts, wenn ich auf meinem eigenen Computer ein lokales LLM
              betreibe?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Wer ein lokales LLM nutzt, braucht die Pseudonymisierungsfunktionen von <span class="brand-name">Private
              Prompts</span> nicht. Allerdings
            bleibt der Prompt Manager ein nützliches Tool, um Eingaben strukturiert zu verwalten und wiederzuverwenden.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Was ist Pseudonymisierung?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Pseudonymisierung ist ein Datenschutzverfahren, bei dem personenbezogene Daten so verändert werden, dass sie
            nicht direkt einer Person zugeordnet werden können. Statt echter Namen oder Identifikatoren kommen
            Ersatzwerte zum Einsatz, die nach der Verarbeitung wiederhergestellt werden können.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Welche Vorteile bietet Pseudonymisierung für den Datenschutz?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Pseudonymisierung reduziert das Risiko, dass persönliche Daten das eigene System verlassen. In <span
              class="brand-name">Private Prompts</span> ist dieses Konzept zentral verankert: Nutzer behalten die volle
            Kontrolle über ihre Informationen, ohne auf den Komfort von KI-Anwendungen verzichten zu müssen.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Wer ist die Zielgruppe von Private Prompts?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            <span class="brand-name">Private
              Prompts</span> richtet sich an Einzelpersonen, die KI kreativ nutzen, dabei aber ihre Datenhoheit bewahren
            möchten. Die Anwendung bietet mit dem Prompt Manager eine sichere, strukturierte Verwaltung von Prompts –
            ähnlich einer gut organisierten Notizsammlung – und schützt gezielt sensible Informationen.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Was ist der Prompt Manager?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Der Prompt Manager ermöglicht die strukturierte Verwaltung von KI-Eingaben. Statt Prompts in
            unübersichtlichen Notizen oder externen Dokumenten zu speichern, bietet die Anwendung eine zentrale Lösung
            zur Organisation und Wiederverwendung. Alle Daten bleiben lokal, wodurch die volle Kontrolle über
            persönliche Informationen erhalten bleibt.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column mb-4">
          <v-card-item>
            <strong>Wie deinstalliere ich Private Prompts?</strong>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Zur Deinstallation genügt es, die Programmdatei zu löschen. Zusätzlich sollte die Konfigurationsdatei von
            <span class="brand-name">Private Prompts</span> entfernt werden, da hier die von dir erstellten
            Datenschutzregeln gespeichert
            werden.
            Der Ort der Konfigurationsdatei kann in den Einstellungen des
            Programms eingesehen werden kann.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- Über mich -->
  <v-container class="press-review" fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <v-icon size="64" color="secondary">mdi-newspaper-variant-outline</v-icon>
        <h2>Pressespiegel</h2>
        <p>Über das Projekt <span class="brand-name">Private Prompts</span> wird auch in Print und Online berichtet:</p>

        <v-btn text href="https://www.sueddeutsche.de/wirtschaft/datenschutz-ki-chat-gpt-deep-seek-li.3208619"
          target="_blank">
          <v-icon left>mdi-link</v-icon>&nbsp;Süddeutsche Zeitung (SZ)</v-btn>&nbsp;„<i>So lassen sich private Daten vor
          der
          KI
          schützen</i>“

        <br>
        <br>

        <v-btn text href="https://prototypefund.de/project/private-prompts/" target="_blank">
          <v-icon left>mdi-link</v-icon>&nbsp;Prototype Fund<br>
        </v-btn> „<i>Wir ermöglichen die datenschutzfreundliche Nutzung von Webdiensten“</i>

        <br>
        <br>

        <v-btn text href="https://prototypefund.de/project/private-prompts/" target="_blank">
          <v-icon left>mdi-link</v-icon>&nbsp;Robert Freund
        </v-btn> „<i>Mit Private Prompts (Open Source) bleiben die Daten auf dem
          eigenen Rechner</i>“

        <br>
        <br>

      </v-col>
    </v-row>
  </v-container>

  <!-- Über mich -->
  <v-container class="about-me" fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <v-icon size="64" color="secondary">mdi-account</v-icon>
        <h2>Über Frank Börncke</h2>
        <p> Hallo, ich bin Frank Börncke, freiberuflicher Softwareentwickler und Initiator von <span
            class="brand-name">Private Prompts</span>. Mein Ziel mit diesem Projekt ist es, Menschen zu ermöglichen, KI
          und Webdienste mit weniger Sorgen um die Privatsphäre zu nutzen. </p>
        <p> <span class="brand-name">Private Prompts</span> gibt dir die Kontrolle über deine Daten - damit du
          moderne Technologien mit mehr Vertrauen und Sicherheit nutzen kannst. Deine Privatsphäre bleibt geschützt – du
          entscheidest, was geteilt wird und was nicht.
          <span class="brand-name">Private Prompts</span> ist mein Beitrag, um die Nutzung von KI sicherer zu
          machen.
        </p>
        <p> Hast du Feedback, Ideen oder möchtest mitwirken? Lass uns gemeinsam eine KI-Anwendung schaffen, die allen
          Nutzerinnen und Nutzern einen echten Mehrwert bietet. Ich freue mich auf den Austausch mit dir! </p>
        <!-- Kontaktmöglichkeiten -->
        <p class="social-links mt-4">
          <v-btn icon color="secondary" href="https://www.boerncke.de/" target="_blank">
            <v-icon size="32">mdi-card-account-phone</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="mailto:frank.boerncke@gmail.com">
            <v-icon size="32">mdi-email</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="https://www.linkedin.com/in/frankboerncke/" target="_blank">
            <v-icon size="32">mdi-linkedin</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="https://x.com/frank_boerncke" target="_blank">
            <v-icon size="32">mdi-twitter</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="https://github.com/fboerncke/private-prompts-prototype" target="_blank">
            <v-icon size="32">mdi-github</v-icon>
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>

  <!-- Logo Section -->
  <v-container class="funding" fluid>
    <p>
      Die Entwicklung von <span class="brand-name">Private Prompts</span> wurde
      im Zeitraum 1.9.2024-28.02.2025 gefördert durch das <strong>Bundesministerium
        für Bildung und Forschung</strong> und den <strong>Prototype Fund</strong> (Förderkennzeichen 01IS24S44).
    </p>
    <v-row justify="center" class="ml-9 mr-7">
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-img src="./logos/bmbf_de.jpg" alt="BMBF Logo" contain max-width="30vw" max-height="30vw"></v-img>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-img src="./logos/PrototypeFund_Logo_smallest.svg" alt="Prototype Fund Logo" contain max-width="30vw"
          max-height="30vw"></v-img>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-img src="./logos/logo-okfn.svg" alt="Open Knowledge Foundation Logo" contain max-width="30vw"
          max-height="30vw"></v-img>
      </v-col>
    </v-row>
    <br />
    <p>
      Die Weiterentwicklung von <span class="brand-name">Private Prompts</span> erfolgt derzeit als privates Projekt.
    </p>
  </v-container>

  <!-- Footer mit rechtlichen Informationen -->
  <v-footer class="footer-banner">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-center">
          <p>&copy; 2025 Frank Börncke, Private Prompts</p>
          <v-btn text href="/impressum">
            Impressum
          </v-btn>
          &nbsp;
          <v-btn text href="/datenschutz">
            Datenschutz
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>

  <!-- Dialog für Video -->
  <v-dialog v-model="videoDialog" max-width="800px">
    <v-card>
      <v-card-text>
        <iframe width="100%" height="450" src="https://app.heygen.com/embeds/f38b706847334f108f42a91b172be357"
          title="Einführungsvideo zu Private Prompts" frameborder="0" allow="encrypted-media; fullscreen;"
          allowfullscreen></iframe>
        <strong>Hinweis</strong>: Die Person in diesem Einführungs-Video ist nicht der Autor von <span
          class="brand-name">Private
          Prompts</span> sondern ein KI-generierter Avatar.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'PrivatePromptsMain',
  components: {
  },
  data() {
    return {
      videoDialog: false,
      hoveredIcon: null,
    };
  },
  methods: {
    playVideo() {
      this.videoDialog = true;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    }
  },
};
</script>

<style scoped>
/* Schriftarten */
body {
  font-family: 'Open Sans', sans-serif;
}

/* Responsive Schriftgrößen */
h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
}

h2 {
  font-size: clamp(2rem, 4vw, 3rem);
}

h3 {
  font-size: clamp(1.75rem, 3vw, 2.5rem);
}

p,
li {
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 1.5em;
  /* Erhöhter Abstand zwischen Absätzen */
}

/* Begrenzung der Textbreite */
.text-content {
  max-width: 800px;
  margin: 0 auto;
}

/* Markierung von "Private Prompts" */
.brand-name {
  font-weight: bold;
  color: var(--accent-color);
}


/* Hintergrundbild mit Overlay */
.v-img {
  position: relative;
  transition: transform 0.3s ease;
}

.v-img>.v-container {
  position: relative;
  z-index: 1;
}

/* Buttons */
.v-btn {
  border-radius: 10px;
  height: 50px;
  transition: all 0.3s ease;
  background-color: var(--button-color);
  text-transform: none;
  /* This prevents text from being converted to uppercase */
}

.v-btn:hover {
  background-color: var(--secondary-color) !important;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

/* Icons */
.v-icon {
  color: var(--primary-color);
}

.v-icon:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

/* Textfarben */
.body {
  background-color: var(--background-color);
  color: var(--text-color);
}

body,
p,
li {
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--secondary-color);
  font-family: 'Montserrat', sans-serif;
}

/* Abschnitte */
.status-message,
.teaser-image-section,
.quickstart,
.how-it-works,
.benefits,
.use-cases,
.feedback,
.frequently-asked-questions,
.press-review,
.about-me,
.funding {
  padding: 50px 15px;
}


.status-message {
  background-color: #F1F1F1;
}

.teaser-image-section {
  background-color: #EAEAEA;
}

.quickstart {
  background-color: #F1F1F1;
}

.how-it-works {
  background-color: #EAEAEA;
}

.benefits {
  background-color: #F1F1F1;
}

.use-cases {
  background-color: #EAEAEA;
}

.feedback {
  background-color: #F1F1F1;
}

.frequently-asked-questions {
  background-color: #EAEAEA;
}

.press-review {
  background-color: #F1F1F1;
}

.about-me {
  background-color: #EAEAEA;
}

.funding {
  background-color: #F1F1F1;
}



/* Karten und Bilder */
.v-card {
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-weight: bold;
  color: var(--secondary-color);
}

.v-card-text {
  color: var(--text-color);
  flex-grow: 1;
}

.v-col.d-flex {
  display: flex;
}

.v-img:hover {
  transform: none;
}

/* Social Links */
.social-links {
  padding-bottom: 0px;
}

.social-links .v-btn {
  margin: 0 10px;
}

.social-links .v-icon {
  color: var(--text-color);
}

/* Footer */
.v-footer {
  background-color: var(--secondary-color) !important;
}

.v-footer .white--text {
  color: #ffffff !important;
}

/* Video-Bereich Anpassungen */
.video-card {
  position: relative;
}

.video-banner {
  position: absolute;
  top: 30px;
  right: -100px;
  background-color: rgba(255, 111, 97, 0.9);
  /* Akzentfarbe mit Transparenz */
  color: white;
  padding-left: 100px;
  padding-right: 100px;
  transform: rotate(45deg);
  font-weight: bold;
  z-index: 2;
}

.video-card .v-img {
  position: relative;
}

.video-card .v-img .v-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-card .v-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-card .v-icon {
  font-size: 64px;
}


/* Banner-Stil */
.banner {
  background: linear-gradient(60deg, #3aad9f, #466673);
  padding: 60px 15px;
}

.banner h1 {
  font-size: 3rem;
  color: #092925;
}

.banner h2 {
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 10px;
}

.banner h3 {
  font-size: 1.4rem;
  color: #264653;
  margin-bottom: 0px;
}

.banner h4 {
  font-size: 1.25rem;
  color: #264653;
  margin-bottom: 10px;
}

.footer-banner {
  background: linear-gradient(60deg, #3aad9f, #466673);
}

/* Carousel Image and Caption */
.carousel-image {
  object-fit: contain;
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.caption {
  position: relative;
  background: #41A693;
  /* Semi-transparent background */
  color: #0A2621;
  padding: 16px;
  border-radius: 5px;
  font-weight: 500;
  margin-bottom: 15px;
}

.caption strong {
  color: var(--accent-color);
}

/* Carousel Item Adjustments */
.v-carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.faq-title {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.faq-answer {
  font-size: 1.125rem !important;
  /* make font italic */
  font-style: italic;
  line-height: 1.5;
  color: var(--text-color);
}

.no-hover:hover {
  background: none !important;
  box-shadow: none !important;
  width: 40px;
  color: green;
  height: 40px;
}

/* Responsive Design for Mobile */
@media (max-width: 600px) {
  .caption {
    font-size: 1rem;
    padding: 5px;
  }
}

@media (max-width: 600px) {
  .banner {
    padding: 40px 15px;
  }
}

@media (min-width: 600px) {

  .how-it-works .v-col,
  .funding .v-col,
  .benefits .v-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
