<!-- PrivatePromptsMain.vue -->
<template>

  <!-- Banner mit Hintergrundfarbe -->
  <v-container fluid class="banner">
    <v-row class="d-flex align-center justify-space-between">
      <!-- Text zentriert im Banner -->
      <v-col cols="12" md="11" class="d-flex justify-center text-center">
        <v-img src="./logo.png" alt="Private Prompts Logo" contain max-width="150"></v-img>
        <div>
          <h3>
            Deine Daten gehören dir!
          </h3>
          <h1 class="font-weight-bold">
            Private Prompts
          </h1>
          <h4>
            KI und Webdienste sicher nutzen
          </h4>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="secondary" text href="/">
          Startseite
        </v-btn>
        &nbsp;
        <v-btn color="secondary" text href="/datenschutz">
          Datenschutz
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

  <v-container fluid class="status-message" style="padding-bottom: 8px;">
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <h2>Impressum</h2>
      </v-col>
      <v-col cols="12" md="10" class="text-center">
        <p>Dr. Frank B&ouml;rncke<br />
          Margaretenstraße 6b<br />
          82152 Krailling</p>

        <h3>Kontakt</h3>
        <p>Telefon: 089 97605480<br />
          Telefax: 089 97605487<br />
          E-Mail: frank.boerncke@gmail.com</p>

        <h3>Umsatzsteuer-ID</h3>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
          DE-245356742</p>

        <h3>Redaktionell verantwortlich</h3>
        <p>Dr. Frank B&ouml;rncke<br />
          Margaretenstra&szlig;e 6b<br />
          82152 Krailling</p>

        <h3>EU-Streitschlichtung</h3>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
            href="https://ec.europa.eu/consumers/odr/" target="_blank"
            rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie
          oben im Impressum.</p>

        <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.</p>

        <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
      </v-col>
    </v-row>

  </v-container>



  <!-- Logo Section -->
  <v-container class="funding" fluid>
    <p>
      Die Entwicklung von <span class="brand-name">Private Prompts</span> wurde
      im Zeitraum 1.9.2024-28.02.2025 gefördert durch das <strong>Bundesministerium
        für Bildung und Forschung</strong> und den <strong>Prototype Fund</strong> (Förderkennzeichen 01IS24S44).
    </p>
    <v-row justify="center" class="ml-9 mr-7">
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-img src="./logos/bmbf_de.jpg" alt="BMBF Logo" contain max-width="30vw" max-height="30vw"></v-img>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-img src="./logos/PrototypeFund_Logo_smallest.svg" alt="Prototype Fund Logo" contain max-width="30vw"
          max-height="30vw"></v-img>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-img src="./logos/logo-okfn.svg" alt="Open Knowledge Foundation Logo" contain max-width="30vw"
          max-height="30vw"></v-img>
      </v-col>
    </v-row>
    <br />
    <p>
      Die Weiterentwicklung von <span class="brand-name">Private Prompts</span> erfolgt derzeit als privates Projekt.
    </p>
  </v-container>

  <!-- Footer mit rechtlichen Informationen -->
  <v-footer class="footer-banner">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-center">
          <p>&copy; 2025 Frank Börncke, Private Prompts</p>
          <v-btn color="secondary" text href="/">
            Startseite
          </v-btn>
          &nbsp;
          <v-btn color="secondary" text href="/datenschutz">
            Datenschutz
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>

</template>

<script>
export default {
  name: 'ImpressumPage',
  data() {
    return {
      videoDialog: false,
      hoveredIcon: null,
    };
  },
  methods: {
    playVideo() {
      this.videoDialog = true;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard');
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    }
  },
};
</script>

<style scoped>
/* Schriftarten */
body {
  font-family: 'Open Sans', sans-serif;
}

/* Responsive Schriftgrößen */
h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
}

h2 {
  font-size: clamp(2rem, 4vw, 3rem);
}

h3 {
  font-size: clamp(1.75rem, 3vw, 2.5rem);
}

p,
li {
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 1.5em;
  /* Erhöhter Abstand zwischen Absätzen */
}

/* Begrenzung der Textbreite */
.text-content {
  max-width: 800px;
  margin: 0 auto;
}

/* Markierung von "Private Prompts" */
.brand-name {
  font-weight: bold;
  color: var(--accent-color);
}


/* Hintergrundbild mit Overlay */
.v-img {
  position: relative;
  transition: transform 0.3s ease;
}

.v-img>.v-container {
  position: relative;
  z-index: 1;
}

/* Buttons */
.v-btn {
  border-radius: 10px;
  height: 50px;
  transition: all 0.3s ease;
  background-color: var(--button-color);
  text-transform: none;
  /* This prevents text from being converted to uppercase */
}

.v-btn:hover {
  background-color: var(--secondary-color) !important;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

/* Icons */
.v-icon {
  color: var(--primary-color);
}

.v-icon:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

/* Textfarben */
.body {
  background-color: var(--background-color);
  color: var(--text-color);
}

body,
p,
li {
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--secondary-color);
  font-family: 'Montserrat', sans-serif;
}

/* Abschnitte */
.status-message,
.teaser-image-section,
.quickstart,
.how-it-works,
.benefits,
.use-cases,
.feedback,
.frequently-asked-questions,
.press-review,
.about-me,
.funding {
  padding: 50px 15px;
}


.status-message {
  background-color: #F1F1F1;
}

.teaser-image-section {
  background-color: #EAEAEA;
}

.quickstart {
  background-color: #F1F1F1;
}

.how-it-works {
  background-color: #EAEAEA;
}

.benefits {
  background-color: #F1F1F1;
}

.use-cases {
  background-color: #EAEAEA;
}

.feedback {
  background-color: #F1F1F1;
}

.frequently-asked-questions {
  background-color: #EAEAEA;
}

.press-review {
  background-color: #F1F1F1;
}

.about-me {
  background-color: #EAEAEA;
}

.funding {
  background-color: #F1F1F1;
}



/* Karten und Bilder */
.v-card {
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-weight: bold;
  color: var(--secondary-color);
}

.v-card-text {
  color: var(--text-color);
  flex-grow: 1;
}

.v-col.d-flex {
  display: flex;
}

.v-img:hover {
  transform: none;
}

/* Social Links */
.social-links {
  padding-bottom: 0px;
}

.social-links .v-btn {
  margin: 0 10px;
}

.social-links .v-icon {
  color: var(--text-color);
}

/* Footer */
.v-footer {
  background-color: var(--secondary-color) !important;
}

.v-footer .white--text {
  color: #ffffff !important;
}

/* Video-Bereich Anpassungen */
.video-card {
  position: relative;
}

.video-banner {
  position: absolute;
  top: 30px;
  right: -100px;
  background-color: rgba(255, 111, 97, 0.9);
  /* Akzentfarbe mit Transparenz */
  color: white;
  padding-left: 100px;
  padding-right: 100px;
  transform: rotate(45deg);
  font-weight: bold;
  z-index: 2;
}

.video-card .v-img {
  position: relative;
}

.video-card .v-img .v-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-card .v-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-card .v-icon {
  font-size: 64px;
}


/* Banner-Stil */
.banner {
  background: linear-gradient(60deg, #3aad9f, #466673);
  padding: 60px 15px;
}

.banner h1 {
  font-size: 3rem;
  color: #092925;
}

.banner h2 {
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 10px;
}

.banner h3 {
  font-size: 1.4rem;
  color: #264653;
  margin-bottom: 0px;
}

.banner h4 {
  font-size: 1.25rem;
  color: #264653;
  margin-bottom: 10px;
}

.footer-banner {
  background: linear-gradient(60deg, #3aad9f, #466673);
}

/* Carousel Image and Caption */
.carousel-image {
  object-fit: contain;
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.caption {
  position: relative;
  background: #41A693;
  /* Semi-transparent background */
  color: #0A2621;
  padding: 16px;
  border-radius: 5px;
  font-weight: 500;
  margin-bottom: 15px;
}

.caption strong {
  color: var(--accent-color);
}

/* Carousel Item Adjustments */
.v-carousel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.faq-title {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.faq-answer {
  font-size: 1.125rem !important;
  /* make font italic */
  font-style: italic;
  line-height: 1.5;
  color: var(--text-color);
}

.no-hover:hover {
  background: none !important;
  box-shadow: none !important;
  width: 40px;
  color: green;
  height: 40px;
}

/* Responsive Design for Mobile */
@media (max-width: 600px) {
  .caption {
    font-size: 1rem;
    padding: 5px;
  }
}

@media (max-width: 600px) {
  .banner {
    padding: 40px 15px;
  }
}

@media (min-width: 600px) {

  .how-it-works .v-col,
  .funding .v-col,
  .benefits .v-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
